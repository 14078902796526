<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		indexGame?: number;
	}>(),
	{ indexGame: 0 }
);

const prepareImgUrl = useImage();
const { isMobileClient } = useWindowClient();

const GREAN_LEAF = `url(${prepareImgUrl(`nuxt-img/top-ten/green-leaf.png`, {
	format: "webp",
	width: 200
})})`;

const isLargeCard = computed(() => props.indexGame >= 9);
</script>

<template>
	<div :class="['game-image-wrapper', { 'large-card': isLargeCard }]">
		<NuxtImg
			format="webp"
			:width="isMobileClient ? 48 : 80"
			:height="isMobileClient ? 126 : 192"
			src="nuxt-img/top-ten/bg-top-number.webp"
			alt="bg-top-number"
			loading="lazy"
			class="game-top-ten"
		/>
		<AText :class="['game-number', { 'large-number': isLargeCard }]">
			{{ indexGame + 1 }}
		</AText>
		<slot name="game-card" />
	</div>
</template>

<style scoped lang="scss">
.game-image-wrapper {
	position: relative;
	display: flex;
	justify-content: flex-end;
	width: 252px;
	height: 192px;
	@include media-breakpoint-down(sm) {
		width: 210px;
		height: 168px;
	}

	&::before {
		content: "";
		top: 110px;
		left: 0;
		right: 135px;
		bottom: 0;
		position: absolute;
		max-width: 74px;
		background: v-bind(GREAN_LEAF) no-repeat center center;
		background-size: 60% auto;
		margin: 0 auto;
		z-index: 1;
		@include media-breakpoint-down(sm) {
			right: 116px;
			top: 62px;
			background-size: 34% auto;
		}
	}

	.game-top-ten {
		margin: auto 0;
		width: auto;
		height: 194px;
		@include media-breakpoint-down(sm) {
			width: auto;
			height: 120px;
		}
	}

	.game-number {
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
		color: rgba(14, 32, 29, 0.66);
		-webkit-text-stroke-width: 2.96052622795105px;
		-webkit-text-stroke-color: #ffbc00;
		font-family: Rubik;
		font-size: 111px;
		font-style: normal;
		font-weight: 700;
		line-height: 150px;
		@include media-breakpoint-down(sm) {
			font-size: 62px;
			left: 14px;
		}
	}
	.large-number {
		left: 3px;
		@include media-breakpoint-down(sm) {
			left: 14px;
		}
	}

	.large-card {
		width: 302px;
		@include media-breakpoint-down(sm) {
			width: 236px;
			height: 168px;
		}
		&::before {
			right: 88px;
		}
	}
}
</style>
